import {Options, Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State, namespace} from 'vuex-class'
import { PPWebModels } from '@/store/ppWebModels/types';
import * as Flashphoner from '@flashphoner/websdk';
import $ from "jquery";
import * as lang from "@/store/lang.js"
import BdsmShowViewingPage from "@/components/pages/bdsm-show/viewing/bdsm-show-viewing.page";
import * as messageModal from "@/store/message-modal.js"

const customer = namespace('customer');
const streamPage = namespace('ppWebModels');
const model = namespace('modelOnWork');
const modelAccount = namespace('modelAccount');

@Options<PPWebModelsViewPage>({
    computed: {
        cModelTags() {
            if (this.state.showAllTags)
                return this.modelTags;
            else
                return this.modelTags.slice(0, this.state.tagShowedCount);
        }
    }
})

export default class PPWebModelsViewPage extends Vue {
    @customer.Getter('getUserData') userData: any;
    @customer.Action('fetchUserData') fetchUserData: any;

    //Страница стрима
    @streamPage.Action('getModelInfoByUsername') getModelInfoByUsername: any;
    @streamPage.Getter('getModelInfo') getModelInfo: any;
    @streamPage.Action('orderAction') orderAction: any;

    //Чат
    @model.Action('getChatMessages') getChatMessages: any;
    @model.Action('sendChatMessages') sendChatMessages: any;
    @model.Getter('chatMessages') chatMessages: any;
    @model.Mutation('setChatMessages') setChatMessages: any;

    //Теги
    @modelAccount.Action('getModelTags') getModelTags: any;
    @modelAccount.Getter('modelTags') modelTags: any;

    //Экшены
    @model.Action('getActions') getActions: any;
    @model.Getter('modelActions') modelActions: any;

    //Магазин
    @modelAccount.Action('getModelProducts') getModelProducts: any;
    @modelAccount.Getter('modelProducts') modelProducts: any;

    lang: any = lang.GetLang();

    //Чат
    myMessage: any = "";
    chatAllMessages : any = [];

    //Экшены
    modelActionActive : any = null;

	//Стрим
    SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
    STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
    Browser = Flashphoner.Browser;
    state : any = {
        modelNickname: "",
        stream: null,
        idStream : "",
        session: null,
        serverUrl: 'wss://18.157.148.99:8443',

        pause: false,
        streamWork: false,
        fullScreen: false,
        showChat: true,
        sessionStatus: '',
        videoElem: null,
        publishStream: null,
        sound: true,
        mobileStreamActionsMenu : false,

        vipDonateMenu: false,
        vipDonateSubmenu: false,

        giftListOpen: false,
        actionListOpen: false,

        privateRequest: false,
        showAllTags: false,
        tagShowedCount: 5,
    };

    //Экшены
    OrderAction(id : number, name : any)
    {
        let _this = this;

        if (this.state.idStream)
        {
            if (!this.userData)
            {
                messageModal.RegistrationOrLogin('Для заказа действий вам нужен аккаунт', 'Создайте новый аккаунт или войдите в существующий');
                return;
            }

            if (this.userData.balance <= 0)
            {
                messageModal.NoFunds();
                return;
            }

            this.orderAction({ streamId: this.state.idStream, actionId: id }).then(function (response : any) {
                if (response.status === false)
                {
                    console.log('Error', response.errors[0]);
                    messageModal.Show("Error", response.errors[0]);
                }
                else
                {
                    _this.myMessage = "Запрос на действие «"+name+"»";
                    _this.SendMessage(null);

                    //_this.state.actionListOpen = false;
                    //messageModal.Show("Отлично!", "Запрос отправлен моделе! Ожидайте...");
                }
            });
        }
        else
        {
            messageModal.Show("Ошибка", "Действия будут доступны после начала стрима");
        }
    }

    ActionInWork()
    {
        let _this = this;

        if (!this.modelActionActive)
            return;

        console.log(Number(this.modelActionActive.started_at), Number((Date.now() / 1000).toFixed()));
        console.log(Number(this.modelActionActive.time) - (Number((Date.now() / 1000).toFixed()) - Number(this.modelActionActive.started_at)));
        if (this.modelActionActive.visualTime > 0)
        {
            this.modelActionActive.visualTime = Number(this.modelActionActive.time) - (Number((Date.now() / 1000).toFixed()) - Number(this.modelActionActive.started_at));
        }
        else
        {
            this.modelActionActive = null;
        }
    }

    //Чат
    SwitchChat()
    {
        this.state.showChat = !this.state.showChat;
    }

    SendDonate()
    {
        this.state.vipDonateMenu = false;
        this.state.vipDonateSubmenu = false;
    }

    SendMessage(e : any)
    {
        if (!this.state.streamWork)
            return;

        var _this = this;

        if (e === null || (e.keyCode == 13 || e.key == "Enter"))
        {
            console.log('userData', this.userData);
            if (!this.userData)
            {
                messageModal.RegistrationOrLogin('Для отправки сообщений моделе вам нужен аккаунт', 'Создайте новый аккаунт или войдите в существующий');
                return;
            }
            else if (this.userData.balance <= 0)
            {
                messageModal.TopUp('Для разблокировки чата нужно обязательное пополнение кошелька на 10 PPSD');
                return;
            }

            this.sendChatMessages({ get : { streamId : this.state.idStream }, post : { text : this.myMessage } }).then(function () {
                _this.GetChatMessages();
            });

            this.$nextTick(() => {
                let chat : any = document.getElementById("js-chat-messages");
                chat.scrollTop = chat.scrollHeight + 1000;
            });

            this.myMessage = "";
        }
    }

    ScrollChatToDown()
    {
        let chat : any = document.getElementById("js-chat-messages");
        chat.scrollTop = chat.scrollHeight + 1000;
    }

    GetChatMessages()
    {
        if (!this.state.streamWork)
            return;

        var _this = this;

        var lastMessageID = 0;
        if (this.chatAllMessages.length)
            lastMessageID = this.chatAllMessages[this.chatAllMessages.length-1].id;

        this.getChatMessages({ idStream : this.state.idStream, messageId : lastMessageID }).then(function() {
            let i = 0;
            _this.chatMessages.forEach(function(data : any)
            {
                setTimeout(function () {
                    //Не вставляем если дубликат (рассинхронизация получения-отправки)
                    for (let msg of _this.chatAllMessages){
                        if (msg.id == data.id)
                            return;
                    }

                    _this.chatAllMessages.push(data);
                    _this.$nextTick(() => _this.ScrollChatToDown());
                }, 200 * i);

                i++;
            });
        });
    }

    SwitchFullScreen()
    {
        this.state.fullScreen = !this.state.fullScreen;

        if (!this.state.fullScreen)
        {
            this.state.showChat = true;
            $('body').removeAttr('style');

            this.$nextTick(() => this.ScrollChatToDown());

            this.state.actionListOpen = false;
        }
        else
        {
            if (<any>$(window).width() < 600)
                this.state.showChat = false;

            $('body').css('overflow', 'hidden');
        }
    }

	CheckStreamStatus()
	{
        var _this = this;

		if (!this.state.streamWork)
		{
			console.log("Check stream status...");

			this.getModelInfoByUsername(this.state.modelNickname).then(function() {
				_this.state.idStream = _this.getModelInfo.streamId;

				console.log('_this.modelTags.length', _this.modelTags.length);
				if (!_this.modelTags.length)
                {
                    _this.getModelTags(_this.getModelInfo.model_id);
                    console.log("Теги не полученны... Получить...")
                }

				console.log('_this.getModelInfo', _this.getModelInfo);

                if (_this.state.idStream)
                {
                    //Включить отображение выполнения экшена (если есть)
                    _this.CheckActiveAction();

                    if (!_this.state.pause)
                        _this.PlayStream();
                }
                else
                {
                    _this.StopStream();
                }
            });
		}
	}

	SwitchStream()
    {
        if (this.state.streamWork)
        {
            this.StopStream(true);
            this.state.pause = true;
        }
        else
        {
            this.PlayStream();
        }
    }

    StopStream(pause = false)
    {
        if (this.state.stream)
        {
            this.state.stream.stop();
            this.state.stream = null;
        }

        this.state.streamWork = false;
        if (!pause)
            this.state.idStream = "";

        this.chatAllMessages = [];
    }

    PlayStream() {
	    var _this = this;

        var options = {
            name: this.state.idStream,
            display: this.state.videoElem
        };

        console.log("Try start stream...", options);

        this.state.stream = this.state.session.createStream(options).on(this.STREAM_STATUS.PLAYING, function(stream: any) {
            console.log("Playing...");

        }).on(_this.STREAM_STATUS.UNPUBLISHED, () => {
            console.log("STREAM_STATUS.UNPUBLISHED");
            _this.StopStream();

        }).on(_this.STREAM_STATUS.FAILED, () => {
            console.log("STREAM_STATUS.FAILED");
            _this.StopStream();

        });

        this.state.stream.play();
        this.state.streamWork = true;
    }

    CheckActiveAction()
    {
        var _this = this;

        if (!this.state.idStream) {
            _this.modelActionActive = null;
            return;
        }

        this.getActions(this.state.idStream).then(function (response : any) {
            if (!response.data.length)
                _this.modelActionActive = null;

            for (let action of response.data)
            {
                if (action.started_at)
                {
                    //Назначаем активный экшен
                    if (!_this.modelActionActive || _this.modelActionActive.id != action.id)
                    {
                        _this.modelActionActive = action;
                        _this.modelActionActive.visualTime = action.time;
                    }

                    break;
                }
            }
        });
    }

    Pad(d : any) {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    mounted() {
        var _this = this;

        messageModal.Init();

        this.state.modelNickname = this.$route.params['username'];

        this.fetchUserData();

        this.getModelInfoByUsername(this.state.modelNickname).then(function() {
            _this.getModelProducts(_this.getModelInfo.model_id);
        });

        //Скроллим чат вниз
        setTimeout(this.ScrollChatToDown, 500);

        //Раз в секунду (таймер для экшенов модели)
        setInterval(this.ActionInWork, 1000);

        //Получаем активный экшен (если есть)
        setInterval(this.CheckActiveAction, 3000);

        //Получение сообщений чата
        setInterval(this.GetChatMessages, 3000);

        //Стрим
        this.state.videoElem = document.getElementById('js-video');

        Flashphoner.init({});
        Flashphoner.createSession({
            urlServer: this.state.serverUrl

        }).on(this.SESSION_STATUS.ESTABLISHED, function(session: any) {
            console.log("Stream session open...");
            _this.state.session = session;

            _this.CheckStreamStatus();
            setInterval(_this.CheckStreamStatus, 5000);
        });
	}
}
